<template>
    <div class="vh bg">
        <van-nav-bar title="宣传推广" left-arrow @click-left="$router.push('/mine')"/>
        <div class="flex-around" style="background-color:white">
            <router-link tag="h3" class="f5 title" :to="{path :'/publicity/scan'}" :class="{'active-title' : activeIndex == 0}">
                推广码
            </router-link>
            <router-link tag="h3" class="f5 title" :to="{path :'/publicity/statistics'}" :class="{'active-title' : activeIndex == 1}">
                推广统计
            </router-link>

        </div>

        <router-view @onActive="onActive"></router-view>
    </div>

</template>

<script>
export default {
    data(){
        return{
            activeIndex:1,           
        }
    },
    created(){

    },
    methods: {
        /**
         * @Description: 更新活动模块状态
         * @Author: Niklaus
         * @Date: 2021-03-03 15:01:22
         * @param {*} index
         */
        onActive(index){
            this.activeIndex = index
        }
    }
    
}
</script>

<style>

</style>
